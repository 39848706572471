<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="news & stories" />
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container"> <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p> </div>
            </section>
            <section class="main-content">
                <div class="container">
                    <p v-html="this.pageData.attributes.field_description_w_summary.value">
                    </p>
                    <div class="attachments-container">
                        <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    </div>
                </div>

                <div class="container">
                    <div class="podcast" id='buzzsprout-small-player-tags-sie'></div>
                    <!--<div class="podcast" id='buzzsprout-small-player-tags-prova'></div>-->
                </div>
                
            </section>
        </main>

    </div>
</template>
<script>
import Attachments from '../components/Attachments.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchSinglePage } from '../libs/drupalClient'
    export default {
        name: 'news',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "15c7bb40-b7b6-47bb-8196-b356fb0d5970",
                newsData: null
            }
        },
        computed: {},
        methods: {
            logourl(index) {
                return process.env.VUE_APP_ENDPOINT + this.pageData.field_page_images[index].attributes.uri.url
            },
            personphoto(node) {
                var f = this.newsData.filter(p => p.attributes.drupal_internal__nid == node.attributes.drupal_internal__nid)
                if(f[0].field_image)
                    return process.env.VUE_APP_ENDPOINT + f[0].field_image.attributes.uri.url
                else 
                    return ''
            }
        },
        mounted() {
            let podcastScript = document.createElement('script')
            podcastScript.setAttribute('src', 'https://www.buzzsprout.com/899635.js?artist=&container_id=buzzsprout-small-player-tags-sie&player=small&tags=SiE&order=date')
            //podcastScript.setAttribute('src', 'https://www.buzzsprout.com/1906806.js?artist=&container_id=buzzsprout-small-player-tags-prova&player=small&tags=prova')
            document.head.appendChild(podcastScript)

            fetchSinglePage(this.currentPageID, {
                include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text', 'field_attachments']
            })
            .then(res => {
                this.pageData = res
                //console.log('pagedata', res)
            })
        }
    }
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.podcast {
    margin: 0 auto;
    max-width: 1030px;
}
</style>